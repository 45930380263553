<template>
    <div class="school-events">
        <div class="school-events__wrap wrapper">
            <div class="school-events__list" v-if="events.length">
                <EduEvent @vote="emit('vote', $event)" v-for="event in events" :key="event.id" :item="event" />
            </div>
            <div v-else-if="!isDone" class="event__banner event__banner--grey">
                <p>Здесь скоро появится мероприятие, ждите обновление</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import EduEvent from '../EduEvent.vue';

const emit = defineEmits(['vote'])

const store = useStore()

const events = computed(() => {
    return store.state.events.events.filter(el => !el.isDone)
})
</script>

<style lang="scss" scoped>
.school-events {
    &__list {
        display: grid;
        gap: 30px;
    }
}
</style>