<template>
    <div class="instruction-page" id="materials" data-nav-section>

        <div class="instruction-page__wrap">
            <div class="wrapper">
                <nav class="instruction-page__breadcrumbs">
                    <router-link to="/#materials" class="breadcrumbs__link">Полезные материалы</router-link>
                    <span class="breadcrumbs__link">{{ currentInstruction?.name }}</span>
                </nav>

                <div class="instruction-page__head">
                    <h1 class="instruction-page__heading">
                        {{ currentInstruction?.name }}
                    </h1>

                    <div class="instruction-page__text">
                        {{ currentInstruction?.description }}
                    </div>
                </div>

                <div class="instruction-page__slider-wrap" v-if="currentInstruction?.id === 'ca27b6d5b20cbbd5a993c9ba6bda5e0e'">
                    <div class="instruction-page__list-wrap">
                        <swiper :slides-per-view="'auto'" :space-between="20"
                            :navigation="{ nextEl: '.instruction-page__next', prevEl: '.instruction-page__prev', }"
                            :class="`swiper-container instruction-page__slider instruction-page__slider--01`" @swiper="onSwiper"
                            @slideChange="onSlideChange">
                            <swiper-slide v-for="item in currentInstructionMaterials.flat()" :key="item.tile" class="material__slide">
                                <MediaItem
                                    class="material__media-item" 
                                    :item="item"
                                    />
                            </swiper-slide>
                            <template v-if="currentInstructionMaterials.length > 2">
                                <div class="swiper-button-prev instruction-page__prev"
                                :class="swiperInstanse?.realIndex === 0 && 'instruction-page__prev--disabled'" @click="swiperInstanse.slidePrev()"></div>
                                <div class="swiper-button-next instruction-page__next"
                                :class="swiperInstanse?.realIndex + 3 == swiperInstanse?.slides.length && 'instruction-page__next--disabled'"
                                @click="swiperInstanse.slideNext()">
                                </div>
                            </template>
                        </swiper>
                    </div>
                </div>

                <ul class="instruction-page__list" v-else-if="currentInstruction.documents?.length">
                    <li class="instructions-page__article-item article-item"
                        v-for="item in currentInstruction.documents"
                        :class="!item.materials.length && !item.publications.length && 'article-item--disabled'"
                        :key="item.id">
                        <RouterLink :to="`/instruction/${id}/${item.id}`" class="article-item__wrap">
                            <div class="article-item__title">
                                {{ item.name }}
                            </div>
                            <ul class="article-item__meta" v-if="item.publications.length || item.materials.length">
                                <li class="article-item__meta-item" v-if="item.publications.length">
                                    {{ item.publications.length }}
                                    <template v-if="currentInstruction?.name === 'Нормативные документы'">{{
                                        plural(item.publications.length, 'документ', 'документа', 'документов')
                                    }}</template>
                                    <template v-else>{{ plural(item.publications.length, 'научная статья', 'научных статьи','научных статей') }}</template>
                                </li>

                                <li class="article-item__meta-item"
                                    v-if="item.materials.filter(el => el.type === 'соп').length">
                                    {{ item.materials.filter(el => el.type === 'соп').length }}
                                    СОП
                                </li>

                                <li class="article-item__meta-item"
                                    v-if="item.materials.filter(el => el.type === 'презентация').length">
                                    {{ item.materials.filter(el => el.type === 'презентация').length }}
                                    {{ plural(item.materials.filter(el => el.type === 'презентация').length,
                                        'презентация',
                                        'презентации', 'презентаций') }}
                                </li>

                                <li class="article-item__meta-item"
                                    v-if="item.materials.filter(el => el.type === 'видео').length">
                                    {{ item.materials.filter(el => el.type === 'видео').length }}
                                    видео
                                </li>
                            </ul>
                        </RouterLink>
                    </li>
                </ul>

                <InProgress v-else />

            </div>

        </div>

        <nav class="instruction-page__breadcrumbs instruction-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/#materials" class="breadcrumbs__link">Полезные материалы</router-link>
                <span class="breadcrumbs__link">{{ currentInstruction?.name }}</span>
            </div>
        </nav>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import plural from 'plural-ru'
import InProgress from '../components/InProgress'
import MediaItem from '../components/MediaItem'
import { Swiper, SwiperSlide } from 'swiper/vue';

const store = useStore()
const route = useRoute()
const { id } = route.params

const swiperInstanse = ref()

const onSwiper = (swiper) => {
    swiperInstanse.value = swiper
}

const currentInstruction = computed(() => {

    let result = false;
    result = store.getters['materials/getInstructionsFromCacheById'](id);

    return result;
})

const currentInstructionMaterials = computed(() => currentInstruction.value.documents.map(el => {
    return el.materials
}))

console.log(currentInstructionMaterials.value.length)


</script>

<style lang="scss" scoped>
.instruction-page {
    overflow: hidden;

    &__prev,
    &__next {
        width: 66px;
        height: 66px;
        bottom: 0;
        top: 0;
        margin: auto;
        background-image: url(@/assets/img/icons/next.svg);
        background-position: center;
        background-size: 66px;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: rgba(51, 51, 51, 0.6);
        transition: 500ms;

        &--disabled {
            opacity: 0;
            transition: 500ms;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .swiper-slide {
        width: 320px;
    }

    &__prev {
        transform: rotate(180deg);

        @media (max-width: 1500px) {
            left: 0;
        }
    }

    &__next {
        right: -15vw;

        @media (max-width: 1700px) {
            right: -10vw;
        }

        @media (max-width: 1500px) {
            right: 0;
        }
    }

    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__slider {
        overflow: visible;
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }

    &__heading {
        margin-bottom: 0;
        margin-top: -2px;
        font-size: 30px;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    &__head {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        margin-bottom: 100px;
        gap: 40px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
        }

        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
    }

    &__text {
        font-size: 20px;
        line-height: 140%;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    &__list {
        margin: 0;
        list-style: none;
        padding: 0;
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @media (max-width:1200px) {
            gap: 20px;
        }

        @media (max-width:991px) {
            grid-template-columns: 1fr;
        }
    }

}

.article-item {
    &--disabled {
        .article-item__wrap {
            background: #FFF;
            pointer-events: none;
        }
    }

    &__wrap {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px 45px;
        padding-right: 100px;
        background: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15L8 8L1 1' stroke='%23333333' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 50px);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        display: block;
        text-decoration: none;
        transition: 350ms;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (max-width: 1200px) {
            padding: 30px 25px;
            border-radius: 20px;
            padding-right: 100px;
        }

        &:hover {
            box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
            transform: scale(1.01);
            transition: 350ms;
        }
    }

    &__title {
        font-size: 25px;
        line-height: 130%;

        @media (max-width: 1200px) {
            font-size: 20px;
        }
    }

    &__meta {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 14px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        &-item {
            display: flex;
            align-items: center;

            &:not(:first-of-type) {
                &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    margin: 0 10px;
                    background-color: #333;
                    display: block;

                    @media (max-width: 768px) {
                        content: none;
                    }
                }
            }
        }
    }
}
</style>