<template>
  <div class="media-page" id="media" data-nav-section>
    <div class="media-page__wrap">
      <div class="wrapper">
        <div class="media-page__head">
          <h1 class="media-page__heading">Медиа</h1>
        </div>

        <Tabs
          class="media-page__tabs"
          v-model:activeTab="activeTab"
          :tabs="tabs"
        />

        <Tabs
          class="media-page__tabs"
          v-model:activeTab="activeTag"
          :tabs="tags"
        />

        <SearchForm class="media-page__search-form" v-model:value="query" />

        <ul class="media-page__list">
          <MediaItem
            v-for="item in media.slice(0, page * perPage)"
            :key="item.tile"
            :item="item"
            class="media-page__media-item"
          />
        </ul>
        <button
          class="media-page__btn"
          @click.prevent="page += 1"
          v-if="page * perPage <= media.length"
        >
          Показать еще
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Tabs from '@/components/Tabs';
import { computed, ref } from 'vue';
import MediaItem from '@/components/MediaItem';
import { useStore } from 'vuex';
import SearchForm from '@/components/SearchForm.vue';

const tabs = ['все', 'новости', 'материалы', 'соп', 'фото', 'видео'];

const store = useStore();

const perPage = 8;
const page = ref(1);
const activeTab = ref('все');
const activeTag = ref('все');
const query = ref('');

const tags = computed(() => {
  const uniqueTags = new Set();

  uniqueTags.add('все');

  store.state.materials.media?.forEach((element) => {
    element.tags?.forEach((tag) => tag.length &&uniqueTags.add(`${tag}`));
  });

  return [...uniqueTags];
});

const media = computed(() => {
  let result = store.state.materials.media;

  result = result.sort((a, b) => {
    if (!a.createDate || !b.createDate) return 1;
    return b.createDate - a.createDate;
  });

  result = result.filter((el) => {
    if (activeTag.value === 'все') return true;

    return el.tags?.includes(activeTag.value);
  });

  result = result.filter((el) => {
    if (activeTab.value === 'все') return true;

    switch (activeTab.value) {
      case 'фото':
        return el.type === 'фотоотчет';
      case 'видео':
        return el.type === 'видеоотчет';
      default:
        return el.type === activeTab.value;
    }
  });

  return result
    .filter((el) => {
      if (!query.value) return true;
      return (
        el.name?.toLowerCase().includes(query.value.toLowerCase()) ||
        el.shortDescription?.toLowerCase().includes(query.value.toLowerCase())
      );
    })
    .map((el) => {
      return {
        ...el,
      };
    })
});
</script>

<style lang="scss" scoped>
.media-page {
  &__wrap {
    padding: 100px 0;

    @media (max-width: 600px) {
      padding: 40px 0;
    }
  }

  &__heading {
    margin-bottom: 0;
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }
  }

  &__tabs {
    margin-bottom: 32px;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-left: auto;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__count {
    color: rgba(22, 23, 30, 0.5);
    margin-bottom: 60px;
  }

  &__search-form {
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-bottom: 70px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__list {
    margin: 0;
    list-style: none;
    padding: 0;
    display: grid;
    align-items: stretch;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 568px) {
      grid-template-columns: 1fr;
    }
  }

  &__btn {
    background: #f1f3f7;
    border-radius: 40px;
    width: 100%;
    max-width: 310px;
    color: #1274e0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    padding: 18px 32px;
  }
}
</style>
